import React from "react";
import styles from './SideBar.module.css';
import { Navigation } from "react-minimal-side-navigation";
import { Icon } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

const SideBar = ({ onSelect = () => {} }) => {
    const handleSelect = (itemId) => {
        onSelect(itemId);
    };

    return (
        <div className={styles.sidebar}>
            <Navigation
                onSelect={({ itemId }) => handleSelect(itemId)}
                items={[
                    {
                        title: (
                            <div className={styles["nav-item"]}>
                                <Icon name="chart bar" className={`${styles["nav-item-icon"]} ${styles.Icon}`} />
                                <label className={styles.sidebarTitle}>전체 통계</label>
                            </div>
                        ),
                        itemId: 'Graph-Camera',
                    },
                    // {
                    //     title: (
                    //         <div className={styles["nav-item"]}>
                    //             <Icon name="chart bar" className={`${styles["nav-item-icon"]} ${styles.Icon}`} />
                    //             <label className={styles.sidebarTitle}>전체 통계(구)</label>
                    //         </div>
                    //     ),
                    //     itemId: 'Graph-Unique',
                    // }
                    // ,
                    // {
                    //     title: (
                    //         <div className={styles["nav-item"]}>
                    //             <Icon name="chart line" className={styles["nav-item-icon"]} />
                    //             <label className={styles.sidebarTitle}>섹션별 통계(구)</label>
                    //         </div>
                    //     ),
                    //     itemId: 'Table',
                    // },
                    // {
                    //     title: (
                    //         <div className={styles["nav-item"]}>
                    //             <Icon name="grid layout" className={`${styles["nav-item-icon"]} ${styles.Icon}`} />
                    //             <label className={styles.sidebarTitle}>일일 상세 데이터</label>
                    //         </div>
                    //     ),
                    //     itemId: 'Table-Data-Detail',
                    // }
                ]}
            />
        </div>
    );
};


export default SideBar;
